body {
    padding-top: 70px;
}

.btn-file {
    position: relative;
    overflow: hidden;
}
.btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
}

.gauge-preview {
    max-width: 400px;
    min-width: 100%;
}

.gauge-overlay {
    position: absolute;
    bottom: 10px;
    left: 10px;
    right: 10px;
    margin: 0;
    padding: 0;
}

.position-relative {
    position: relative;
}

.position-absolute {
    position: absolute;
    top: 0;
    right: 0;
}

.position-hidden {
    opacity: 0;
}